<template>
  <div class="details-container">
    <v-row v-if="loadingInProgress" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-if="!loadingInProgress">
      <v-card>
        <v-card-title>Change password</v-card-title>
        <hr />
        <v-form ref="resetPasswordForm" v-model="resetPasswordValid" class="details-sub-container"
         @submit.prevent="resetPassword">
            <TextField
              class="details-text-field margin-right-8"
              label="Current password"
              placeholder="Current password"
              :rules="[required, FORM_VALIDATION.password]"
              :required="true"
              :error="error"
              type="password"
              @emitTextValue="setValue"
            />
            <span @click="routeToResetPasswordPage()" class="forget-password">Forgot your password ?</span>
          <v-row>
            <TextField
              class="details-text-field margin-right-8"
              label="New password"
              placeholder="New password"
              :rules="[required, FORM_VALIDATION.password]"
              :required="true"
              type="password"
              @emitTextValue="setValue"
            />
            <div></div>
            <TextField
              class="details-text-field"
              label="Repeat password"
              placeholder="Repeat password"
              :rules="[required, FORM_VALIDATION.password]"
              :required="true"
              :error="error"
              :error-messages="errorMessages"
              type="password"
              @emitTextValue="setValue"
              />
          </v-row>
          <Button
            type="submit"
            value="Save changes"
            color="primary"
            height="40px"
            :disabled="!resetPasswordValid"
          />
        </v-form>
      </v-card>
    </div>
    <!--<v-snackbar :timeout="timeout" v-model="snackbar" bottom
    right>{{ toasterMessage }}</v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="toasterMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
<div v-if="openDialogForgotPassword">
  <v-dialog v-model="openDialogForgotPassword" max-width="370px" persistent>
    <v-card class="dialog-modal">
      <v-form v-model="valid" @submit.prevent="updateEmail">
        <v-card-title v-html="forgotPasswordText" />
        <v-flex class="pa-6">
            <TextField
                @emitTextValue="getEmailAddress"
                label="Email address"
                :rules="emailRules"
                placeholder="E.g. hello@timeflyz.com"
                :value="emailForgot"
            />
          <v-row align="center" justify="end">
            <Button
              class="cancel-button"
              buttonClass="error-link"
              @buttonClicked="cancleAlert"
              type="button"
              value="Cancel"
            />
            <Button
                type="submit"
                value="Submit"
                color="primary"
                height="40px"
                width="100%"
                :disabled="!valid"
            />
          </v-row>
        </v-flex>
      </v-form>
        <div v-if="showDialog">
          <v-dialog v-model="showDialog" max-width="370px">
            <Dialog
              :dialogData="toasterMessage"
              :onCloseDialog="closeDialog"
              mode="snackbar-error"
            />
          </v-dialog>
        </div>
    </v-card>
  </v-dialog>
  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Button from '@/common/Button.vue';
import TextField from '@/common/TextField.vue';
import Dialog from '@/common/ErrorDialog.vue';
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';
import validation from '@/validations';

const {
  resetPasswordService,
  forgotPasswordEmail,
} = require('@/services/user/user.service');

export default {
  name: 'ResetPassword',
  data() {
    return {
      emailRules: [validation.FORM_VALIDATION.required, validation.FORM_VALIDATION.email],
      emailForgot: '',
      required: validation.FORM_VALIDATION.required,
      valid: true,
      resetPasswordValid: true,
      timeout: SNACKBAR_TIMEOUT,
      openDialog: false,
      openDialogForgotPassword: false,
      snackbar: false,
      toasterMessage: '',
      loadingInProgress: false,
      currentPassword: '',
      repeatPassword: '',
      newPassword: '',
      errorMessages: '',
      error: false,
      FORM_VALIDATION: validation.FORM_VALIDATION,
      forgotPasswordText: 'Forgot Password',
    };
  },
  components: {
    TextField,
    Button,
    Dialog,
  },
  computed: {
    ...mapState({
      successMessage: state => state.user.sucessMessage,
    }),
  },
  watch: {
    newPassword() {
      this.checkPassword();
    },
    repeatPassword() {
      this.checkPassword();
    },
    successMessage() {
      if (this.successMessage !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
  },

  methods: {
    ...mapActions(['resetPassword', 'resetSuccessMessage']),
    routeToResetPasswordPage() {
      this.openDialogForgotPassword = true;
    },
    checkPassword() {
      if (this.repeatPassword && this.newPassword) {
        if (this.newPassword !== this.repeatPassword) {
          this.errorMessages = 'Password do not match';
          this.error = true;
        } else {
          this.errorMessages = '';
          this.error = false;
        }
      }
    },
    setValue(value, label) {
      switch (label) {
        case 'Current password':
          this.currentPassword = value;
          break;
        case 'Repeat password':
          this.repeatPassword = value;
          break;
        case 'New password':
          this.newPassword = value;
          break;
        default: break;
      }
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.openDialogForgotPassword = false;
    },
    async resetPassword() {
      try {
        const resetPasswordResponse = await resetPasswordService(
          this.currentPassword,
          this.newPassword,
        );
        if (resetPasswordResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.$refs.resetPasswordForm.reset();
          this.snackbar = true;
          this.openDialog = true;
          this.toasterMessage = TOASTER_MESSAGE.CHANGES_SAVED;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = 'Please enter all mandatory fields. New password and current password should not be same';
      }
    },
    getEmailAddress(emailForgot) {
      this.emailForgot = emailForgot;
    },
    cancleAlert() {
      this.snackbar = false;
      this.openDialogForgotPassword = false;
    },
    async updateEmail() {
      try {
        const resetPasswordResponse = await forgotPasswordEmail({ email: this.emailForgot, userType: 'APP' });
        if (resetPasswordResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.$refs.resetPasswordForm.reset();
          this.snackbar = true;
          this.openDialog = true;
          this.toasterMessage = 'Password sent successfully to your provided email address.';
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = 'Email is not found';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  .v-card__title {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .details-sub-container {
    padding: 23px 16px;
  }
  .details-text-field {
    width: 270px;
  }
  .margin-right-8 {
    margin-right: 8px;
  }
}
  .forget-password {
    color: #4a90e2;
    cursor: pointer;
    font-size: 15px;
    position: absolute;
    top: 152px;
    left: 154px;
  }

// responsiveness design
@media only screen and (max-width: 425px) {
  .details-text-field {
    width: 100% !important;
    &.margin-right-8 {
      margin-right: unset !important;
    }
  }
}
</style>
