<template>
  <div class="details-container">
    <v-row v-if="loadingInProgress" align-content="center" justify="center" >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-if="!loadingInProgress">
    <v-card class="card-container">
      <v-card-title>Preferences</v-card-title>
      <hr />
      <v-card-text class="currency-container">
        <p class="heading" style="margin-bottom: 40px">Select currency</p>
        <v-radio-group
          v-model="dialogm"
          column
          v-for="currency in currencies"
          :key="currency._id"
        >
          <v-radio
            :label="`${currency.currencyFor} (${currency.shortName}) ${
              currency.currencyFullName ? currency.currencyFullName : ''
            }`"
            :value="currency"
            @change="handleRadioChange(currency)"
            style="margin-top: -10px; padding: 0; margin-left: 10px"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="toasterMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
    </div>
  </div>
</template>
<script>
import {
  SNACKBAR_TIMEOUT,
  TOASTER_MESSAGE,
  SORT_LISTS,
  HTTP_STATUS_CODE,
} from '@/constants';
import Dialog from '@/common/ErrorDialog.vue';
import { mapActions } from 'vuex';

const {
  getCurrenciesPreference,
  saveCurrency,
  getLoggedInUserDetailService,
} = require('@/services/user/user.service');

export default {
  name: 'Preferences',
  data() {
    return {
      dialogm: null,
      currencies: [],
      snackbar: false,
      openDialog: false,
      toasterMessage: '',
      loadingInProgress: false,
      userCurrencyId: '',
    };
  },
  components: {
    Dialog,
  },
  created() {
    this.getLoggedInUserDetails();
  },
  methods: {
    ...mapActions(['setCurrencyData']),
    async getLoggedInUserDetails() {
      this.loadingInProgress = true;
      try {
        const getLoggedInUserResponse = await getLoggedInUserDetailService();
        if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.userCurrencyId = getLoggedInUserResponse.data.vatgst;
          this.setCurrencyData(getLoggedInUserResponse.data.vatgstDetails[0]);
          this.allCurrencies();
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
    async allCurrencies() {
      this.loadingInProgress = true;
      try {
        const getCurrenciesResponse = await getCurrenciesPreference();
        if (getCurrenciesResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.currencies = getCurrenciesResponse.data;
          const selectedCurrency = this.currencies.find(
            currency => currency._id === this.userCurrencyId,
          );
          if (selectedCurrency) {
            this.dialogm = selectedCurrency;
          }
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
    async handleRadioChange(data) {
      try {
        const saveCurrencyDetails = await saveCurrency({ vatgstId: data._id });
        if (saveCurrencyDetails.status === HTTP_STATUS_CODE.SUCCESS) {
          this.setCurrencyData(data);
          this.snackbar = true;
          this.openDialog = true;
          this.toasterMessage = TOASTER_MESSAGE.CURRENCY_SAVE;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  .card-container {
    padding-bottom: 40px;
  }
  .currency-container {
    padding-left: 40px;
  }
  .heading {
    font-size: 20px;
    font-weight: 700;
    color: #ff4500;
  }
  .v-card__title {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .details-sub-container {
    padding: 23px 16px;
  }
  .details-text-field {
    width: 270px;
  }
  .margin-right-8 {
    margin-right: 8px;
  }
  .margin-bottom-18 {
    margin-bottom: 18px;
  }
  ::v-deep .theme--light.v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>
