<template>
  <div class="details-container">
    <v-card class="margin-bottom-18">
      <v-card-title>Get in touch with us</v-card-title>
      <hr />
      <div class="details-sub-container">
        <!-- <v-flex class="mb-2">
					<span class="label">Mobile number</span>
          <vue-tel-input class="details-text-field" v-model="phone" :disabled="true"></vue-tel-input>
        </v-flex> -->
        <TextField
          class="details-text-field"
          :disabled="true"
          label="Email address"
          value="info@timeflyz.co"
          placeholder="E.g. eve@timeflyz.com"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import TextField from '@/common/TextField.vue';

export default {
  name: 'GetInTouch',
  components: {
    TextField,
  },
  data() {
    return {
      phone: '+61 2 8330 6771',
    };
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  .v-card__title {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .details-sub-container {
    padding: 23px 16px;
  }
  .details-text-field {
    width: 270px;
  }
  .margin-right-8 {
    margin-right: 8px;
  }
  .margin-bottom-18 {
    margin-bottom: 18px;
  }
  ::v-deep .theme--light.v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>
