<template>
  <div class="account-container" v-bind:style= "[isMobile ? { marginTop: '50px'} : { marginTop: '0px'}]">
    <div>
      <h1>Account</h1>
      <v-card class="side-navbar" max-width="270" min-width="270">
        <div
          @click="itemClick('personaldetails')"
          :class="{'selected': selectedItem === 'personaldetails'}"
          class="card-section"
        >
          <b>Personal Details</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div
          @click="itemClick('currency')"
          :class="{'selected': selectedItem === 'currency'}"
          class="card-section"
        >
          <b>Currency</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div
          @click="itemClick('changepassword')"
          :class="{'selected': selectedItem === 'changepassword'}"
          class="card-section"
        >
          <b>Change Password</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div
          @click="itemClick('getintouch')"
          :class="{'selected': selectedItem === 'getintouch'}"
          class="card-section"
        >
          <b>Get in touch with us</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div @click="openAlert()" class="card-section logout-card">
          <b>Log out</b>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </v-card>
    </div>
    <div class="account-details-container">
      <div v-if="selectedItem === 'personaldetails'">
        <PersonalDetails />
      </div>
      <div v-if="selectedItem === 'currency'">
        <Preferences />
      </div>
      <div v-if="selectedItem === 'changepassword'">
        <ResetPassword />
      </div>
      <div v-if="selectedItem === 'getintouch'">
        <GetInTouch />
      </div>
    </div>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="alertMessage"
          :onDialogResponse="logOut"
          :onCloseDialog="closeDialog"
          mode="alert"
          title="Log out?"
          alertActionMessage="Log out"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { datadogLogs } from '@datadog/browser-logs';

import PersonalDetails from '@/components/Account/PersonalDetails.vue';
import Preferences from '@/components/Account/Preferences.vue';
import ResetPassword from '@/components/Account/ResetPassword.vue';
import GetInTouch from '@/components/Account/GetInTouch.vue';
import Dialog from '@/common/Dialog.vue';

export default {
  name: 'Account',
  data() {
    return {
      selectedItem: 'personaldetails',
      openDialog: false,
      alertMessage:
        'If you log out, you will need to log back in. Are you sure you want to log out?',
      isMobile: window.innerWidth <= 768,
    };
  },
  components: {
    PersonalDetails,
    Preferences,
    ResetPassword,
    GetInTouch,
    Dialog,
  },
  created() {
    this.selectedItem = this.$route.params.mode || 'personaldetails';
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.mode': function () {
      this.selectedItem = this.$route.params.mode;
    },
  },
  methods: {
    itemClick(value) {
      this.$router.push({
        path: `../account/${value.toLowerCase()}`,
      });
    },
    openAlert() {
      this.openDialog = true;
    },
    closeDialog() {
      this.openDialog = false;
    },
    async logOut() {
      datadogLogs.clearUser();
      window.FB.logout();
      this.$cookies.remove('token');
      this.$cookies.remove('isLoggedIn');
      this.$router.push({ path: '/login' });
      this.openDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.account-container {
  padding: 2.9rem 0 2.9rem 0;
  display: flex;
  .card-section {
    padding: 12px 16px;
    border-bottom: 1px solid #dcdde6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .side-navbar {
    margin: 26px 40px 0 0;
    .selected {
      color: $primary-color;
      .v-icon {
        color: $primary-color;
      }
    }
  }
  .logout-card {
    display: flex;
  }
  .account-details-container {
    width: 100%;
  }
}

// responsiveness design
@media only screen and (max-width: 1024px) {
  .account-container {
    flex-direction: column;
  }
  .side-navbar {
    display: flex;
    flex-direction: row;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
    justify-content: center;
    .card-section {
      border-bottom: unset !important;
      padding: 12px 10px !important;
    }
  }
  .logout-card {
    display: none !important;
  }
  .account-details-container {
    margin-top: 20px;
  }
}
</style>
