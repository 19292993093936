<template>
  <div class="details-container">
    <v-row v-if="loadingInProgress" align="center" justify="center" >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-if="!loadingInProgress">
      <v-card class="margin-bottom-18">
        <v-card-title>Personal Details</v-card-title>
        <hr />
        <v-form ref="form" v-model="valid" class="details-sub-container"
        @submit.prevent="updateUserDetails">
          <v-row>
            <TextField
              class="details-text-field margin-right-8"
              :value="userDetails.firstName"
              label="First name"
              :rules="firstNameRules"
              :required="true"
              @emitTextValue="setValue"
              placeholder="E.g. Eve"
            />
            <TextField
              class="details-text-field margin-right-8"
              :value="userDetails.lastName"
              label="Last name"
              :rules="lastNameRules"
              :required="true"
              @emitTextValue="setValue"
              placeholder="E.g. Polastri"
            />

            <v-flex class="mb-2">
							<span class="label">Mobile</span>
							<vue-tel-input class="tel-input" v-model="userDetails.mobile" @validate="validateMobileNumber"></vue-tel-input>
							<span class="phone-error" v-if="mobileError !== ''">{{ mobileError }}</span>
						</v-flex>
          </v-row>
          <v-flex class="mb-2">
            <span class="label">Date of birth (guests must be above 18)</span>
              <v-menu
                v-model="showDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  single-line
                  :value="computedDate"
                  outlined
                  append-icon="mdi-calendar"
                  readonly
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                  @click:append="showDate = true"
                  clearable
                ></v-text-field>
                </template>
                <v-date-picker
                :value="now"
                 no-title
                @input="inputDate"
                :max="maxDate"
                ></v-date-picker>
              </v-menu>
          </v-flex>
          <TextField
            class="details-text-field"
            :disabled="true"
            label="Email address"
            :value="userDetails.email"
            placeholder="E.g. eve@timeflyz.com"
          />
          <Button
            type="submit"
            value="Save changes"
            color="primary"
            height="40px"
            :disabled="!valid || mobileError!==''"
          />
        </v-form>
      </v-card>
    </div>
    <!--<v-snackbar :timeout="timeout" v-model="snackbar" bottom
    right>{{ toasterMessage }}</v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="toasterMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Button from '@/common/Button.vue';
import TextField from '@/common/TextField.vue';
import Dialog from '@/common/ErrorDialog.vue';
import 'vue-tel-input/dist/vue-tel-input.css';
import {
  SNACKBAR_TIMEOUT,
  HTTP_STATUS_CODE,
  TOASTER_MESSAGE,
} from '@/constants';
import validation from '@/validations';

const {
  getLoggedInUserDetailService,
  updateUserDetailService,
} = require('@/services/user/user.service');

export default {
  name: 'PersonalDetails',
  data() {
    return {
      required: validation.FORM_VALIDATION.required,
      firstNameRules: [validation.FORM_VALIDATION.required, validation.FORM_VALIDATION.firstname],
      lastNameRules: [validation.FORM_VALIDATION.required, validation.FORM_VALIDATION.lastname],
      valid: true,
      timeout: SNACKBAR_TIMEOUT,
      snackbar: false,
      openDialog: false,
      mobileError: '',
      toasterMessage: '',
      loadingInProgress: false,
      userDetails: {},
      showDate: false,
      maxDate: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      now: moment().subtract(18, 'years').format('YYYY-MM-DD'),
    };
  },
  components: {
    TextField,
    Button,
    Dialog,
  },
  computed: {
    computedDate() {
      return this.userDetails.dob
        ? moment(this.userDetails.dob).format('ddd DD MMM, YYYY')
        : '';
    },
  },
  created() {
    this.getLoggedInUserDetails();
  },
  methods: {
    setValue(value, label) {
      switch (label) {
        case 'First name':
          this.$set(this.userDetails, 'firstName', value);
          break;
        case 'Last name':
          this.$set(this.userDetails, 'lastName', value);
          break;
        case 'Mobile number':
          this.$set(this.userDetails, 'mobile', value);
          break;
        default: break;
      }
      this.$set(this.userDetails, 'fullName', `${this.userDetails.firstName} ${this.userDetails.lastName}`);
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
    },
    async getLoggedInUserDetails() {
      this.loadingInProgress = true;
      try {
        const getLoggedInUserResponse = await getLoggedInUserDetailService();
        if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.userDetails = {
            firstName: getLoggedInUserResponse.data.firstName,
            lastName: getLoggedInUserResponse.data.lastName,
            fullName: `${getLoggedInUserResponse.data.firstName} ${getLoggedInUserResponse.data.lastName}`,
            mobile: getLoggedInUserResponse.data.mobile,
            email: getLoggedInUserResponse.data.email,
            dob: moment(getLoggedInUserResponse.data.dob).format('YYYY-MM-DD'),
          };
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
    async updateUserDetails() {
      try {
        const updatedUserResponse = await updateUserDetailService(this.userDetails);
        if (updatedUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.snackbar = true;
          this.openDialog = true;
          this.toasterMessage = TOASTER_MESSAGE.CHANGES_SAVED;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    validateMobileNumber(telnumber) {
      const valid = validation.FORM_VALIDATION.mobile(telnumber.formatted);
      this.mobileError = valid === true ? '' : valid;
    },
    inputDate(text) {
      this.$set(this.userDetails, 'dob', text);
      this.showDate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-container {
  .v-card__title {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .details-sub-container {
    padding: 23px 16px;
  }
  .details-text-field {
    width: 270px;
  }
  .margin-right-8 {
    margin-right: 8px;
  }
  .margin-bottom-18 {
    margin-bottom: 18px;
  }
  .tel-input {
    border: 2px solid #DCDEE6;
    background-color: #fff;
    height: 38px;
  }
  .phone-error {
    color: #F00;
  }
}

// responsiveness design
@media only screen and (max-width: 425px) {
  .details-text-field {
    width: 100% !important;
    &.margin-right-8 {
      margin-right: unset !important;
    }
  }
}
</style>
